import React from 'react';
import PropTypes from "prop-types";
import Container from 'components/ui/Container';
import Logo from './../../assets/images/ustore.png';

import * as Styled from './styles';
import { graphql, useStaticQuery } from "gatsby";

const ContactInfo = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
        strapiContactPage_en: strapiContactPage(locale: {eq: "en"}) {
          get_in_touch
          contact
          phone_label
          phone_number
          customer_service_label
          customer_service_email_address
          marketing_label
          marketing_email
          address_label
          address_value
        }
        strapiContactPage_zh: strapiContactPage(locale: {eq: "zh"}) {
          get_in_touch
          contact
          phone_label
          phone_number
          customer_service_label
          customer_service_email_address
          marketing_label
          marketing_email
          address_label
          address_value
        }
    }
  `);

  const translations = gql[`strapiContactPage_${locale}`];

  return (
    <Container section>
        <Styled.ContactInfoItem>
            <h1>{translations.get_in_touch}</h1>
            <Styled.ContactInfoLogo>
                <Styled.Image src={Logo} alt='ustore'/>
            </Styled.ContactInfoLogo>
            <Styled.Bold>{translations.contact}</Styled.Bold>
            <Styled.Title>{translations.phone_label}</Styled.Title>
            <Styled.Span>{translations.phone_number}</Styled.Span>
            <Styled.Title>{translations.customer_service_label}</Styled.Title>
            <Styled.Span>{translations.customer_service_email_address}</Styled.Span>
            <Styled.Title>{translations.marketing_label}</Styled.Title>
            <Styled.Span>{translations.marketing_email}</Styled.Span>
            <Styled.Title>{translations.address_label}</Styled.Title>
            <Styled.Span>{translations.address_value}</Styled.Span>
        </Styled.ContactInfoItem>
    </Container>
  );
};

ContactInfo.propTypes = {
  locale: PropTypes.string,
};

ContactInfo.defaultProps = {
  locale: 'en',
};

export default ContactInfo;
