import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Contact from '../components/Contact';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const ContactPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout page='contact' locale={language}>
        <SEO title="Contact"/>
        <Contact locale={language}/>
    </Layout>
  );
};

export default ContactPage;
