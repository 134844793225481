import styled from 'styled-components';
import tw from 'twin.macro';

export const ContactInfoItem = styled.div`
  background: #fff;
  width: 544px;
  align-items: center;
  padding: 0 32px 32px 32px;
  text-align: center;
  ${tw`m-auto`};
  h1{
    ${tw`text-xl3 pb-12 font-bold block sm:hidden`};
  }
`;

export const ContactInfoLogo = styled.div`
  ${tw`w-full m-auto`};
`;

export const Image = styled.img`
  width: 118px;
  height: 118px;
  margin: 32px auto;
`;

export const Bold = styled.b`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #4D4545;
`;

export const Title = styled.p`
  margin-top:32px;
  font-weight: normal;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  align-items: center;
  color: #383C3F;
`;

export const Span = styled.div`
  width: 178px; 
  margin: 6px auto;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  align-items: center;
  letter-spacing: 0.5px;
  color: #383C3F;
`;